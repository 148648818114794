<template>
  <div class="page pp">
    <div class="content waterfall">
      <vue-waterfall-easy isRouterLink hrefKey="path" :gap="12"   srcKey="tpic" :imgsArr="articles" :imgWidth="290" :maxCols="6" ref="waterfall"
                          @scrollReachBottom="getData" @preloaded="preloaded">
        <div class="info" slot-scope="props">
          <div class="text">
            <div class="title">{{props.value.title}}</div>
            <div class="time">{{props.value.mtime}}</div>
          </div>
        </div>
      </vue-waterfall-easy>
<!--      <div class="articles waterfall">-->
<!--        <ul class="list">-->
<!--          <router-link tag="li" class="li" :to="'/lifes/'+item.id" v-for="(item,xh) in articles" :key="xh">-->
<!--            <div class="title">{{item.title}}</div>-->
<!--            <div class="time">{{item.mtime}}</div>-->
<!--            <div class="img"><img :src="item.tpic"></div>-->
<!--          </router-link>-->
<!--        </ul>-->
<!--      </div>-->
    </div>
  </div>
</template>
<script>
import vueWaterfallEasy from 'vue-waterfall-easy'
import {list} from '@/api/api'
export default {
    name: 'articles',
    components: {
        vueWaterfallEasy
    },
    data() {
        return {
            articles: [],
            page: 1,
            totalpage: 1
        }
    },
    props: {
        dataList: {
            type: Array,
            default() {
                return []
            }
        }
    },
    created() {
        this.getData()
    },
    methods: {
        preloaded(){
            console.log('a,b')

        },
        getData() {
            let data = {
                app_key: '1D5AF16FD7D90EE3C5CF70D088249505',
                model_name: 'life',
                perpage: 30,
                page: this.page,
                order: ["mtime DESC", "id DESC"]
            }
            list(data).then((data) => {
                this.articles = this.articles.concat(data.data.list)// data.data.list
                this.articles.forEach((el)=>{
                    this.$set(el,'path','/lifes/'+el.id)

                })
                this.page++
                this.totalpage = Math.ceil(data.data.total / data.data.perpage)
                if (this.page > this.totalpage) {
                    this.$refs.waterfall.waterfallOver()
                }
            })

        },
    },
    computed: {}
}
</script>

